import React from 'react';

// Components
import { WeekView } from 'components/calendar/week';
import { Heading } from 'components/core/typo';
import { Button } from 'components/core/button';
// import { Button as Btn } from 'components/ui/button';
import { EmptyState } from 'components/core/empty';
import { SelectCronIntervalWithConfirm } from 'components/tutor-form/select-cron';

// Hooks
import { useTranslationRegularIntervalCrons } from 'hooks/crons'
import { useAbstractPeriods } from 'hooks/useAbstractPeriods';
import { useTranslation } from 'react-i18next';
import { useMemo, useCallback } from 'react';
// import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"


const suggestionsBySchool = {
  "ecole-secondaire-vanier": [
    {from_cron: "30 11 * * 1", to_cron: "00 12 * * 1", name: "Lundi 11h30-12h30"},
    {from_cron: "30 11 * * 2", to_cron: "00 12 * * 2", name: "Mardi 11h30-12h30"},
    {from_cron: "30 11 * * 3", to_cron: "00 12 * * 3", name: "Mercredi 11h30-12h30"},
    {from_cron: "30 11 * * 4", to_cron: "00 12 * * 4", name: "Jeudi 11h30-12h30"},
    {from_cron: "30 11 * * 5", to_cron: "00 12 * * 5", name: "Vendredi 11h30-12h30"},
    {from_cron: "45 15 * * 1", to_cron: "00 20 * * 1", name: "Lundi 15h45-20h00"},
    {from_cron: "45 15 * * 2", to_cron: "00 20 * * 2", name: "Mardi 15h45-20h00"},
    {from_cron: "45 15 * * 3", to_cron: "00 20 * * 3", name: "Mercredi 15h45-20h00"},
    {from_cron: "45 15 * * 4", to_cron: "00 20 * * 4", name: "Jeudi 15h45-20h00"},
  ],
  "ecole-secondaire-de-la-camaradiere": [
    {from_cron: "00 12 * * 1", to_cron: "00 13 * * 1", name: "Lundi 12h-13h"},
    {from_cron: "00 12 * * 2", to_cron: "00 13 * * 2", name: "Mardi 12h-13h"},
    {from_cron: "00 12 * * 3", to_cron: "00 13 * * 3", name: "Mercredi 12h-13h"},
    {from_cron: "00 12 * * 4", to_cron: "00 13 * * 4", name: "Jeudi 12h-13h"},
    {from_cron: "00 12 * * 5", to_cron: "00 13 * * 5", name: "Vendredi 12h-13h"},
    {from_cron: "15 16 * * 1", to_cron: "00 20 * * 1", name: "Lundi 16h15-20h00"},
    {from_cron: "15 16 * * 2", to_cron: "00 20 * * 2", name: "Mardi 16h15-20h00"},
    {from_cron: "15 16 * * 3", to_cron: "00 20 * * 3", name: "Mercredi 16h15-20h00"},
    {from_cron: "15 16 * * 4", to_cron: "00 20 * * 4", name: "Jeudi 16h15-20h00"},
  ],
  "polyvalente-de-charlesbourg": [
    {from_cron: "15 12 * * 1", to_cron: "00 13 * * 1", name: "Lundi 12h15-13h15"},
    {from_cron: "15 12 * * 2", to_cron: "00 13 * * 2", name: "Mardi 12h15-13h15"},
    {from_cron: "15 12 * * 3", to_cron: "00 13 * * 3", name: "Mercredi 12h15-13h15"},
    {from_cron: "15 12 * * 4", to_cron: "00 13 * * 4", name: "Jeudi 12h15-13h15"},
    {from_cron: "15 12 * * 5", to_cron: "00 13 * * 5", name: "Vendredi 12h15-13h15"},
    {from_cron: "30 16 * * 1", to_cron: "00 20 * * 1", name: "Lundi 16h30-20h00"},
    {from_cron: "30 16 * * 2", to_cron: "00 20 * * 2", name: "Mardi 16h30-20h00"},
    {from_cron: "30 16 * * 3", to_cron: "00 20 * * 3", name: "Mercredi 16h30-20h00"},
    {from_cron: "30 16 * * 4", to_cron: "00 20 * * 4", name: "Jeudi 16h30-20h00"},
  ],
  "ecole-secondaire-roger-comtois": [
    {from_cron: "15 12 * * 1", to_cron: "00 13 * * 1", name: "Lundi 12h15-13h15"},
    {from_cron: "15 12 * * 2", to_cron: "00 13 * * 2", name: "Mardi 12h15-13h15"},
    {from_cron: "15 12 * * 3", to_cron: "00 13 * * 3", name: "Mercredi 12h15-13h15"},
    {from_cron: "15 12 * * 4", to_cron: "00 13 * * 4", name: "Jeudi 12h15-13h15"},
    {from_cron: "15 12 * * 5", to_cron: "00 13 * * 5", name: "Vendredi 12h15-13h15"},
    {from_cron: "30 16 * * 1", to_cron: "00 20 * * 1", name: "Lundi 16h30-20h00"},
    {from_cron: "30 16 * * 2", to_cron: "00 20 * * 2", name: "Mardi 16h30-20h00"},
    {from_cron: "30 16 * * 3", to_cron: "00 20 * * 3", name: "Mercredi 16h30-20h00"},
    {from_cron: "30 16 * * 4", to_cron: "00 20 * * 4", name: "Jeudi 16h30-20h00"},
  ],
  "ecole-secondaire-pointe-levy": [
    {from_cron: "00 11 * * 1", to_cron: "15 13 * * 1", name: "Lundi 11h-13h15"},
    {from_cron: "00 11 * * 2", to_cron: "15 13 * * 2", name: "Mardi 11h-13h15"},
    {from_cron: "00 11 * * 3", to_cron: "15 13 * * 3", name: "Mercredi 11h-13h15"},
    {from_cron: "00 11 * * 4", to_cron: "15 13 * * 4", name: "Jeudi 11h-13h15"},
    {from_cron: "00 11 * * 5", to_cron: "15 13 * * 5", name: "Vendredi 11h-13h15"},
    {from_cron: "30 16 * * 1", to_cron: "00 20 * * 1", name: "Lundi 16h30-20h00"},
    {from_cron: "30 16 * * 2", to_cron: "00 20 * * 2", name: "Mardi 16h30-20h00"},
    {from_cron: "30 16 * * 3", to_cron: "00 20 * * 3", name: "Mercredi 16h30-20h00"},
    {from_cron: "30 16 * * 4", to_cron: "00 20 * * 4", name: "Jeudi 16h30-20h00"},
  ]
}


export function SelectWeeklyAvailabilities({value, setValue, preferedSchools}){
  const { t } = useTranslation('common');
  const [date, events] = useAbstractPeriods(value);
  const translatedCrons = useTranslationRegularIntervalCrons(value);
  const sortedCrons = useMemo(()=>translatedCrons.sort((a,b)=>a.fromMinutesInt>b.fromMinutesInt), [translatedCrons])
  const validation = useCallback((value)=>{
    if (!value) return;
    const [minutes, hours, , , day] = value.from_cron.split(" ");
    const [minutes2, hours2, , , ] = value.to_cron.split(" ");
    const time = parseInt(hours)*60+parseInt(minutes);
    const time2 = parseInt(hours2)*60+parseInt(minutes2);
    if (time<11*60){
      return {code: "availability-must-be-after-11h00"}
    }
    if ((time>13*60+15)& (time<15*60+45)){
      return {code: "availability-cannot-be-between-13h15-and-15h45"}
    }
    if (time>20*60){
      return {code: "availability-cannot-be-between-after-20h"}
    }
    if ((time2-time>=0) && (time2-time<60)){
      return {code: "period-must-be-60min-minimum"}
    }
    // If friday cannot be after 15h45
    if (day==="5" && time2>15*60+45){
      return {code: "availability-cannot-be-after-15h45-on-friday"}
    }
    return;
  }, [])
  const suggestions = useMemo(()=>preferedSchools? preferedSchools.slice(0,1).flatMap(s=>suggestionsBySchool[s && s.slug] || []): [], [preferedSchools]);
  //  Filter suggestions that have an 15 minutes overlap with value
  // parseInt(fromDayInt)*24*60 + fromHour*60 + fromMinutes
  const suggestionsWithMinutes = suggestions.map(s=>({...s, 
    fromMinutesInt: parseInt(s.from_cron.split(" ")[4])*24*60 + parseInt(s.from_cron.split(" ")[1])*60 + parseInt(s.from_cron.split(" ")[0]), 
    toMinutesInt: parseInt(s.to_cron.split(" ")[4])*24*60 + parseInt(s.to_cron.split(" ")[1])*60 + parseInt(s.to_cron.split(" ")[0])}))
  // If suggetsions has an overlap of >1min with translatedCrons, then filter it out
  const filteredSuggestions = suggestionsWithMinutes.filter((s)=>{
    return !translatedCrons.some(t=>Math.max(s.fromMinutesInt, t.fromMinutesInt)<Math.min(s.toMinutesInt, t.toMinutesInt))
  }, [])
  
  // Group suggetsions by weekday
  const groupedSuggestions = filteredSuggestions.reduce((acc, s)=>{
    if (!acc[s.from_cron.split(" ")[4]]) acc[s.from_cron.split(" ")[4]] = [];
    acc[s.from_cron.split(" ")[4]].push(s);
    return acc;
  }, {})

  // console.log(chrono.fr.parse('Jeudi de 13h à 16h').start.knownValues)
  // Grouped sortedCrons by weekday
  const groupedCrons = sortedCrons.reduce((acc, s)=>{
    if (!acc[s.from_cron.split(" ")[4]]) acc[s.from_cron.split(" ")[4]] = [];
    acc[s.from_cron.split(" ")[4]].push(s);
    return acc;
  }, {})
  return <div>
            <Heading title={t("interval.title")}>
            </Heading>
              <SelectCronIntervalWithConfirm validation={validation} onConfirmation={d=>setValue(value? [...value, d]: [d])}/>
             
              {groupedSuggestions && Object.keys(groupedSuggestions).length>0 && 
              <div className='gap-x-3 gap-y-1 md:flex md:justify-between mt-2 border p-3 rounded-md'> 
                <div>
                    <label className="font-medium text-sm md:mr-5">{t("interval.suggestions")}</label>
                    <p className="text-sm">{t("interval.suggestions-description")}</p>
                </div>
                {groupedSuggestions && Object.keys(groupedSuggestions).map((k, i)=>
                  <div key={i} className="flex flex-col gap-y-1">
                    <p className="font-medium text-sm">{t(`weekday.${k}`)}</p>
                    {groupedSuggestions[k].map((d,i)=><Button key={i} size="xs" color="gray" onClick={()=>setValue(value? [...value, d]: [d])}>{d.name}</Button>)}
                  </div>
                )}
              </div>}
              <div className='mt-3 border p-3 rounded-md'>
                <label className="font-medium text-sm mt-5">{t("interval.your-selection")}</label>
                {translatedCrons.length===0?
                <EmptyState title={t("interval.no-selection")}/>:
                <div className="space-y-1 my-2 grid grid-cols-1 gap-y-5 gap-x-6">
                  {groupedCrons && Object.keys(groupedCrons).map((k, i)=>
                    <div key={i} className="flex flex-col ">
                      <p className="font-medium text-sm">{t(`weekday.${k}`)}</p>
                      {groupedCrons[k].map((d,i)=><div key={i} className='flex items-center justify-between py-1 hover:bg-gray-50'>
                        <p>{d.baseStr}
                          {/* If from_cron is 15h45, add badge to show it's for in person onle  */}
                          {parseInt(d.from_cron.split(" ")[1])<15? <span className=" text-red-600 bg-red-100 text-xs px-1 py-0.5 ml-1 rounded-md">{t("interval.in-person-only")}</span>: null}

                        </p>
                        <Button  size="xs" color="delete" onClick={()=>setValue(value?.filter((val)=>val.from_cron!==d.from_cron && val.to_cron!==d.to_cron))}>{t("interval.delete")}</Button>
                      </div>)}
                    </div>
                  )}
               
                </div>}
              </div>
            <div className="mt-3">
              <WeekView  abstract date={date} events={events} numTicks={6} minHour={10} maxHour={20}/>
            </div>
         </div>
}