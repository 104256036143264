import { useState, useCallback, useEffect, useRef } from 'react';
import { useIsMounted } from 'hooks/useIsMounted';
import { toast } from "sonner"

/*
  Example usage

*/
export function useAPI(method, params, {onResult, onCatch, executeOnce=false, immediate=true, validateParams}={}){

  const [result, setResult] = useState();
  const [loading, setLoading] = useState(immediate);
  const [error, setError] = useState();
  const activeController = useRef();
  const isMounted = useIsMounted();

  const execute = useCallback((extraParams)=>{
    if (!method) return;
    if (validateParams && !validateParams(params)) return;
    setLoading(true);
    setError();
    const handleSuccess = (res)=>{
      if (!isMounted.current) return;
      if (onResult){
        onResult(res);
      }
      setResult(res);
      setLoading(false);
      
    }
    const onError = (res)=>{
      if (!isMounted.current) return;
      setLoading(false);
      try {
        res.json()  
            .then(({detail, code, payload})=>{
              const status = res.status;
                setLoading(false); 
                setError({code, detail, payload});
                if (status === 422){
                  toast.error("Une erreur 422 est survenue. Le problème sera résolu sous peu.")
                }
                else if (status === 404){
                  toast.error("La page que vous cherchez n'existe pas ou vous n'avez pas les permissions pour y accéder.")
                }
                else if (status === 500){
                  toast.error("Une erreur 500 est survenue, veuillez réessayer.")
                }

                else if ((status === 401) || (status === 403)|| (status === 400)){
                  // Do nothing because it's handled by the global error handler
                }
                else {
                  toast.error(`Une erreur ${status} (${code}) est survenue. Nous travaillons à la résoudre.`)
                }
                })
            .catch(()=>{setLoading(false); setError({code: 'default'}); if (onCatch){onCatch()} });
      }
      catch {
        setLoading(false); 
        if (onCatch) onCatch();
        setError({code: 'default'});
      }
    }

    if (activeController.current) activeController.current.abort();
    const [promise, {controller}] = method(extraParams? ({...extraParams, ...params}): params)
    activeController.current = controller;
    return promise.then(handleSuccess).catch(onError);
  }, [method, onResult, params])

  // Fire when page reload
  useEffect(()=>{
    if (executeOnce) execute()
  }, [])
  
  // Fire when page reload
  useEffect(() => {
    if (immediate) execute();
  }, [execute]);

  return [result, {loading, error, execute, setResult, setError}];
}

