/* eslint-disable no-unused-vars */
import React from 'react';

// Hooks
import { Trans, useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react';
import { useAPI } from 'hooks/useAPI';
import { useAuth } from 'hooks/useAuth';
// API
import { login } from 'api/auth';

// Components
import { CenterViewLayout } from 'components/core/layouts/center';
import { Button } from 'components/core/button'
import { TextInput } from 'components/core/inputs'
import { Error, Heading, TextBox, TextBoxError } from 'components/core/typo'
import { Navigate, useSearchParams } from 'react-router-dom';
import { SEO } from 'components/core/seo';

export function LoginPage(){
  const { t } = useTranslation(["auth"]);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const {setUser}= useAuth();
  const [searchParams] = useSearchParams();
  const [result, {loading, error, execute}] = useAPI(login, {email, password},
                                                  {onResult: (({user, totp_challenge, sms_challenge})=>{
                                                    if (user && !totp_challenge && !sms_challenge){
                                                      setUser(user);
                                                    }
                                                  }),
                                                  immediate: false});
  const onSubmit = useCallback((e)=>{
    if (e) e.preventDefault();
    execute();
  }, [execute]);

  if (result){
    if (result.totp_challenge){
      // Should redirect to TOTP
      return <Navigate to={searchParams.get("ref")? "/totp-challenge?ref="+searchParams.get("ref"): "/totp-challenge"}/>
    }
    if (result.sms_challenge){
      // Should redirect to SMS
      return <Navigate to={searchParams.get("ref")? "/sms-login?ref="+searchParams.get("ref"): "/sms-login"}/>
    }
    if (result.user && result.user.documents_signed==false){
      // Should redirect to documents
      return <Navigate to="/sign-required-documents" state={result}/>
    }
    if (searchParams.get("ref")){
      return <Navigate to={searchParams.get("ref")} state={result}/>
    }
    return <Navigate to="/" state={result}/>
  }

  return <CenterViewLayout>
            <SEO title={t("login.title")} description={t("login.description")} />
            <form className="space-y-6" onSubmit={onSubmit}>
            <img src="/assets/logo-HD.png" className="h-auto max-w-full mx-auto max-h-24"/>
            <Heading title={t('login.title')} description={t("login.description")} className={"max-w-xs"}/>
            {searchParams.get("msg")?
              <TextBox>{t("login.msg."+searchParams.get("msg"))}</TextBox>:
              searchParams.get("error")?
              <TextBoxError>{t("login.msg."+searchParams.get("error"))}</TextBoxError>:
              null
            }
            <TextInput
                label={t("login.email.label")}
                autoComplete="email"
                type="email"
                required
                onChange={setEmail}/>
            <TextInput
                label={t("login.password.label")}
                type="password"
                autoComplete="current-password"
                required
                hideEyeOnPassword={true}
                onChange={setPassword} />
            <Button color="inlineLink" size="link" to="/forgot-password">
                {t("login.forgot-password")}
            </Button>

            <Button disabled={!email || !password} color="active" block size="xl" loading={loading}  type="submit">
                {t("login.submit")}
            </Button>
            {error? <Error.Text {...error}/>: null}
            </form>
            {/*  For SIGNUP */}
            <div className='py-8'>
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 text-gray-500 bg-white">{t("login.signup")}</span>
                </div>
              </div>
              <Heading description={t("login.signup-tutor.description")}>
                <Button color="inlineLink" size="link" to="/inscription-tuteur">
                    {t("login.signup-tutor.link")}
                </Button>
              </Heading>
            </div>

            {/* HELP */}
            <div className='py-8'>
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 text-gray-500 bg-white">{t("login.help-new-tutor.label")}</span>
                </div>
              </div>
              <Heading description={<Trans i18nKey="login.help-new-tutor.description" components={{a: <a className='text-orange-500 hover:opacity-80 underline'/>}} t={t}/>}/>

            </div>

         </CenterViewLayout>
}
