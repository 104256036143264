import React from "react";

// Components
import { Heading } from 'components/core/typo';
import { CenterViewLayout } from "components/core/layouts/center";
import { Button } from "components/core/button";
import { TutoringGrid } from "components/widgets/tutoring_grid";
import { GeneralSessionGrid } from "components/widgets/general-sessions-grid";
import { TutorAccountActivation } from "components/widgets/account-activation";

// Utils
import { getCurrentPeriod } from "utils/period";

// API
import { retrieveTutorInscriptionStatus } from 'api/tutor';
import { EventGrid } from "components/events/calendar";

// Hooks
import { useTranslation } from 'react-i18next';
import { useAuth } from "hooks/useAuth";
import { Spinner } from "components/core/spinner";
import { TutorInscriptionStatus } from "components/widgets/inscription_status";
import { useAPI } from 'hooks/useAPI';
import { SetupTOTPBanner } from "@/components/settings/security/setup_totp";

function HomeBanner({ children }) {
    return <>


        <SetupTOTPBanner />
    <div className="flex md:text-center flex-col items-center justify-center border-b h-[25vh] md:h-[40vh] min-h-[300px] bg-gradient-to-br from-gray-50 from-60% to-orange-100/80 px-6">
        <div className="max-w-xl lg:max-w-5xl">
            {children}
        </div>
    </div>
</>
}

/*
  Add a study session when logged.
*/
function TutorHomePage() {
    const { t } = useTranslation("common");
    const period = getCurrentPeriod();
    const [report, { error, execute:reloadStatus }] = useAPI(retrieveTutorInscriptionStatus);
    const isActiveInPeriod = report?.active_periods?.find(p => p.slug === period) !== undefined;
    return <div>
        {!report ? <Spinner className={"mx-auto my-auto"} /> :
            !report.ready && isActiveInPeriod ? <TutorInscriptionStatus report={report} error={error} /> :
                !report.ready && !isActiveInPeriod ? <TutorAccountActivation onActivation={reloadStatus}/> :
                <>
                    <HomeBanner>
                        <Heading title={t("home.title")} longDescription={t("home.description")} />
                        <Button color="inlineLink" to="/ajouter-tutorat">
                            {t("home.actions.add-study-session")}
                        </Button>
                    </HomeBanner>
                    <TutoringGrid />
                </>
        }
    </div>
}

function TeacherHomePage() {
    const { t } = useTranslation("common");
    return <>
        <HomeBanner>
            <Heading title={t("home.title")} longDescription={t("home.teacher-description")} />
            <Button color="inlineLink" to="/ajouter-recuperation">
                {t("home.actions.add-recuperation")}
            </Button>
        </HomeBanner>
        <GeneralSessionGrid />
    </>
}

function PartnerHomePage() {
    const { t } = useTranslation("common");
    return <>
        <HomeBanner>
            <Heading title={t("home.title")} longDescription={t("home.partner-description")} />
            <div className="flex flex-col items-left md:items-center">
                <Button color="inlineLink" to="/ajouter-maison-jeunes">
                    {t("general-sessions.add-maison-jeunes")}
                </Button>
                <Button color="inlineLink" to="/ajouter-tutorat-prive">
                    {t("general-sessions.add-tutorat-prive")}
                </Button>
            </div>
        </HomeBanner>
        <GeneralSessionGrid />
    </>
}

function CoachHomePage() {
    const { t } = useTranslation("common");
    return <>
        <HomeBanner>
            <Heading title={t("home.title")} longDescription={t("home.coach-description")} />
            <Button color="inlineLink" to="/ajouter-coach-meeting">
                {t("general-sessions.add-session.coach")}
            </Button>
        </HomeBanner>
        <EventGrid />
    </>
}

export function HomePage() {

    const { user } = useAuth();
    if (!user) {
        return <CenterViewLayout className="py-6">
            <Spinner className="mx-auto" size="md" />
        </CenterViewLayout>
    }

    switch (user.role) {
        case "tutor": return <TutorHomePage />
        case "teacher": return <TeacherHomePage />
        case "partner": return <PartnerHomePage />
        case "coach": return <CoachHomePage />
        default: return null;
    }
}

