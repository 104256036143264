import { fetchCall } from 'api';

/*
  Check if user is logged in
*/
export function isLoggedIn(){
  const options = {
    method: "GET",
    endpoint: "/portal/auth/is-logged-in"
  }
  return fetchCall(options)
}


/*
  Get profile
*/
export function retrieveProfile(){
  const options = {
    method: "GET",
    endpoint: "/portal/auth/user"
  }
  return fetchCall(options)
}

/*
  Refresh the access token
*/
export function refreshAccessToken(){
  const options = {
    method: "POST",
    endpoint: "/portal/auth/update-token"
  }
  return fetchCall(options)
}

/*
  Login with email and password
*/
export function login({email, password}){
  const options = {
    method: "POST",
    endpoint: "/portal/auth/login",
    body: {email, password}
  }
  return fetchCall(options)
}
/*
  Login with email and password
*/
export function loginAdmin({email, password}){
  const options = {
    method: "POST",
    endpoint: "/portal/auth/admin-login",
    body: {email, password}
  }
  return fetchCall(options)
}
/*
  Logout
*/
export const logout = () => {
  const options = {
    method: "POST",
    endpoint: "/portal/auth/logout",
  }
  return fetchCall(options)
};


/*
  Send an email to reset the password
*/
export const sendResetPasswordEmail = ({email}) => {
  const options = {
    method: "POST",
    endpoint: "/portal/auth/send-reset-password-email",
    body: {email}
  }
  return fetchCall(options)
};

/*
Reset the password with a signed token.
*/
export const resetPassword = ({password, token}) => {
  const options = {
    method: "POST",
    endpoint: "/portal/auth/reset-password",
    body: {password, token}
  }
  return fetchCall(options)
};


/* Change password */
export const changePassword = ({oldPassword, newPassword}) => {
  const options = {
    method: "POST",
    endpoint: "/portal/auth/change-password",
    body: {currentPassword:oldPassword, password:newPassword}
  }
  return fetchCall(options)
};

/*
Validate the current token
*/
export const validateResetPasswordToken = ({token}) => {
  const options = {
    method: "GET",
    endpoint: "/portal/auth/validate-reset-password",
    query: {token}
  }
  return fetchCall(options)
};

/*
  Retrieve recent connections
*/
export const listRecentConnections = ({page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET",
    endpoint: "/portal/auth/connections",
    query: {
      page,
      limit,
      orderBy,
      orderDirection}
  }
  return fetchCall(options)
};

/*Retrieve is has unseen messages */
export const hasUnseenConversations = () => {
  const options = {
    method: "GET",
    endpoint: "/portal/auth/has-unseen-messages"
  }
  return fetchCall(options)
}


/*
Update subscription
*/
export const updateSubscription = ({token, subscribe}) => {
  const options = {
    method: "POST",
    endpoint: "/portal/auth/update-supscription",
    body: {token, subscribe}
  }
  return fetchCall(options)
};

/*
Generate URI for setup
*/
export const generateTOTPUri = () => {
  const options = {
    method: "POST",
    endpoint: "/portal/auth/generate-totp-uri"
  }
  return fetchCall(options)
};

/*
Validate totp 
 */
export const validateTOTP = ({token}) => {
  const options = {
    method: "POST",
    endpoint: "/portal/auth/validate-totp",
    body: {token}
  }
  return fetchCall(options)
}

// Deactive totp
export const deactivateTOTP = ({token}) => {
  const options = {
    method: "POST",
    endpoint: "/portal/auth/deactivate-totp",
    body: {token}
  }
  return fetchCall(options)
}

/*
Validate totp 
 */
export const ansertTOTPChallenge = ({token}) => {
  const options = {
    method: "POST",
    endpoint: "/portal/auth/answer-totp-challenge",
    body: {token}
  }
  return fetchCall(options)
}